import React from "react"
import LayoutSite from "../components/layouts/layoutSite"
import Title from "../components/crumbs/title"
import PageSeo from "../components/crumbs/pageSeo"
import MoreArticles from "../components/crumbs/moreArticles"
import { useStaticQuery, graphql } from "gatsby"
import CategoryTopNews from "../components/crumbs/categoryTopNews"
import Ads from "../components/ads"
import BoxAd from "../components/crumbs/boxAd"

export default function Home() {
  const { allStrapiArticle } = useStaticQuery(graphql`
    query {
      allStrapiArticle(limit: 32, sort: {order: DESC, fields: publish}, filter: {status: {eq: "live"}, category: {name: {eq: "Gazdaság"}}}) {
        edges {
          node {
              title
              category {
                  name
              }
              cover {
                  childImageSharp {
                      fluid {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
              excerpt
          }
        }
      }
    }
  `)

  const articlesTopNews = allStrapiArticle.edges.map(_ => _.node)

  return (
    <>
      <PageSeo
        title="Minden Percben | Gazdaság"
        description="Minden amiről tudnia érdemes a gazdaságban. Mert minden perc érdekes!"
        keywords="gazdaság, tőzsdei hírek, pénzpiacok, befektetés, hitel"
        canonical="https://www.mindenpercben.hu/gazdasag"
        canonicalMobile = "https://m.mindenpercben.hu/gazdasag"        
      />
      <LayoutSite>        
        <Title category="Gazdaság" />

        <CategoryTopNews articles={articlesTopNews} category="gazdasag"/>

        <BoxAd isBlock={true} ad={ <iframe src="https://mindenpercben.hu/r/1213172.html" width="1100" height="320" frameborder="0" scrolling="no"></iframe> } />

        <MoreArticles slug="/kereses/gazdasag/1" text="A Gazdaság rovat további cikkei" />
      </LayoutSite>
    </>
  )
}